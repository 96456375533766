import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { ContentRender } from '../ContentRender/ContentRender';
import MaxWidth from '../Layout/MaxWidth';
import TextContent from '../ui/TextContent';
import { FaqPage } from './FaqPage';

const Wrapper = styled('div')``;

const Header = styled('h1')`
  margin: 1rem 0 3rem !important;
  text-align: center;
`;

const PageWrapper = styled(MaxWidth)`
  padding-bottom: 4rem;
  max-width: 960px;
  p {
    margin-bottom: 1rem;
  }
`;

const ContentPage = (props) => {
  const { page } = props;

  if (!page)
    return (
      <PageWrapper>
        <LoadingLine heightPx={27} widthRem={15} />
        <LoadingLine
          heightPx={14}
          widthRem={30}
          randomizeWidthBy={10}
          count={10}
          className={css`
            margin-bottom: 12px;
          `}
        />
      </PageWrapper>
    );

  return (
    <Wrapper>
      <Header>{page.name}</Header>
      <ContentRender data={page.data} />
      <PageWrapper>
        {page.name.search('FAQ') !== -1 ? (
          <FaqPage page={page} />
        ) : (
          <>
            <TextContent content={page.content} />
          </>
        )}
      </PageWrapper>
    </Wrapper>
  );
};

export default ContentPage;
